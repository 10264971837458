import { getEndOfDate } from '@shared/utils';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { GlobalStateService } from '@core/services/global.state.service';
import { CreateConversationStateService } from '@shared/components/conversation/create-conversation.state.service';
import { LinkType } from '@shared/enums';
import { SelectOption } from '@shared/interfaces';
import {
  ConversationModel, InteractionCreateInterface, ProductModel,
  ProductTypeModel, SaleCreateInterface, SendEmailRequestModel, TaskCreateInterface
} from '@shared/models';
import { getWorkflowType } from 'app/modules/content-management/workflow-templates/models/workflow-type.enum';
import { combineLatest, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { STATUS_OPTIONS, TYPE_OPTIONS } from '@shared/constants/select-options.constants';
import { ConversationType } from '@shared/configs/conversation-types.config';
import { ConcernTypeModel } from '@shared/models/concern-type.models';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';
import { MemberProspectInstancesModel } from '@shared/models/memberprospectinstances.model';
import { UserBranchModel } from '@shared/models/user-Branch.model';
import { ConversationApiService, TaskApiService } from '@core/services/api.services';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { EmailNotificationPermissionModel } from '@shared/models/email-notificationmodel';
import { EnailNotificationApiService } from '@core/services/api.services/email-notification-api.service';
import { EmailNotificationDto } from '@core/services/dto/email-notification.dto';

@Component({
  template: ''
})
export abstract class CreateConversationCommonComponent extends autoUnsubscribeMixin() implements OnInit {
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Output() conversationTypeChange: EventEmitter<ConversationType> = new EventEmitter();
  @Output() formValueChanged = new EventEmitter<boolean>();
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;
  initialState: any = {};
  interaction: InteractionCreateInterface;
  task: TaskCreateInterface;
  sale: SaleCreateInterface;

  formLinks: SearchMemberProspectOptionsModel[] = [];
  form: FormGroup;
  formWorkflow: FormGroup;
  workflowOptions: SelectOption<number>[] = [];
  productTypeOptions: ProductTypeModel[];
  concernTypeOptions: ConcernTypeModel[];
  productOptions: ProductModel[];
  emailNotificationPermission: EmailNotificationPermissionModel[];

  addingInteraction = false;
  addingTask = false;
  addingSale = false;
  isPanelClosed = false;

  statusOptions = STATUS_OPTIONS;
  typeOptions = TYPE_OPTIONS;
  userBranchOptions: UserBranchModel[];

  private availableLinksForTask: string[] = [LinkType.member, LinkType.conversation, LinkType.prospect];
  private availableLinksForSale: string[] = [LinkType.conversation, LinkType.account, LinkType.member];

  constructor(
    protected fb: FormBuilder,
    protected state: CreateConversationStateService,
    protected globalState: GlobalStateService,
    private toast: CustomSnackbarService,
    private taskService: TaskApiService,
    private conversationService: ConversationApiService,
    private htmlElementRef: ElementRef,
    private service: EnailNotificationApiService
  ) {
    super();

    this.form = this.fb.group({
      conversationType: [null, Validators.required],
      productType: ['', Validators.required],
      productId: [{ value: '', disabled: true }, Validators.required],
      status: ['Opened', Validators.required],
      subject: ['', [Validators.minLength(3), Validators.maxLength(100), Validators.required]],
      description: ['', [Validators.maxLength(2500), Validators.minLength(3), Validators.required]],
    });

    this.formWorkflow = this.fb.group({
      workflowTemplateId: ''
    });
  }

  abstract saveConversationType(): Observable<ConversationModel>;

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.globalState.userBranches$().pipe(takeUntil(this.destroyed$))
      .subscribe(userBranch => {
        this.userBranchOptions = userBranch;
      });
    this.formWorkflow.disable();
    this.initializeWorkflow();

    this.globalState.productTypes$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(productTypes => {
        this.productTypeOptions = productTypes;
      });

    this.globalState.concernTypes$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(concernTypes => {
        this.concernTypeOptions = concernTypes.filter(type => type.value !== 0);
      });

    combineLatest(
      [this.form.get('productType').valueChanges,
      this.globalState.products$()]
    )
      .pipe(
        filter(([productId, products]) => products.some(p => p.type.id === productId))
      )
      .subscribe(([_, products]) => {
        this.form.get('productId').enable();
        this.productOptions = products;
      });

    this.form.get('productType').valueChanges.subscribe(productTypeId => {
      this.globalState.getProducts(productTypeId);
    });

    this.form.get('conversationType').valueChanges.subscribe(type => {
      this.conversationTypeChange.emit(type);
    });

    this.service.getEmailNotificationPermission(false).subscribe(
      (response: EmailNotificationDto[]) => {
        this.emailNotificationPermission = (response);
      });

    this.form.get('assigneeId').valueChanges.subscribe(name => {
      if (name.hasOwnProperty('isGroup')) {
        if (name.isGroup === true) {
          this.service.getEmailNotificationPermission(true).subscribe(
            (response: EmailNotificationDto[]) => {
              this.emailNotificationPermission = (response);
            });
        }
      }
    });

    this.formWorkflow.valueChanges.subscribe(() => {
      this.formValueChanged.emit(true);
    });

  }

  save(): void {
    this.saveConversationType().subscribe(conversation => {
      this.globalState.updateConversations();
      this.toast.success(conversation.message);
      if (conversation.assignee.isGroup === true) {
        let emailAddresses = [];
        emailAddresses = conversation.emails;
        const conversationType = conversation.conversationType;
        const userGroup = conversation?.assignee?.name;
        const sendEmailPermission = this.emailNotificationPermission?.
          find(item => item.crmObjectType.name.toLowerCase() === conversationType.toLowerCase());

        if (sendEmailPermission?.hasPermission === true) {
          this.globalState.sendBulkEmails(conversationType, emailAddresses, userGroup);
        }
      }
      if (conversation.assignee.isGroup === false) {
        const conversationType = conversation.conversationType;
        const sendRequest = new SendEmailRequestModel();
        sendRequest.to.address = conversation?.emails?.toString();
        const sendEmailPermission = this.emailNotificationPermission?.
          find(item => item.crmObjectType.name.toLowerCase() === conversationType.toLowerCase());

        if (sendEmailPermission?.hasPermission === true) {
          this.globalState.SendEmail(sendRequest, conversationType);
        }
      }
      if (this.addingInteraction) {
        this.interaction.conversationId = conversation.id;
        this.state.setInteraction(this.interaction).subscribe(() => {
          this.globalState.interactionUpdated.next(true);
        });
      }

      if (this.addingTask) {
        const linksToAddToTask = this.formLinks.filter(x => this.availableLinksForTask.includes(x.type.toLowerCase()));

        this.task.dueDate = getEndOfDate(new Date(this.task.dueDate)).toJSON();
        this.task.links = [{ type: 'Conversation', ref: conversation.id.toString() }].concat(linksToAddToTask);
        this.state.setTask(this.task).subscribe((data) => {
          if ('id' in data) {
            this.globalState.updateTasks();
            this.taskService.notifyTaskUpdate();
            this.taskService.getTaskUpdateNotifier();
          }
          if (data?.assignee?.isGroup === true) {
            let emailAddresses = [];
            emailAddresses = data?.assignedToEmails;
            const CRMType = 'Task';
            const userGroup = data?.assignee?.name;
            const sendEmailPermission = this.emailNotificationPermission?.
              find(item => item.crmObjectType.name.toLowerCase() === CRMType.toLowerCase());

            if (sendEmailPermission?.hasPermission === true) {
              this.globalState.sendBulkEmails(CRMType, emailAddresses, userGroup);
            }
          }
          if (data?.assignee?.isGroup === false) {
            const CRMType = 'Task';
            const sendRequest = new SendEmailRequestModel();
            sendRequest.to.address = data?.assignedToEmails?.toString();
            const sendEmailPermission = this.emailNotificationPermission?.
              find(item => item.crmObjectType.name.toLowerCase() === CRMType.toLowerCase());

            if (sendEmailPermission?.hasPermission === true) {
              this.globalState.SendEmail(sendRequest, CRMType);
            }
          }
        });
      }

      if (this.addingSale) {
        const linksToAddToSale = this.formLinks.filter(x => this.availableLinksForSale.includes(x.type.toLowerCase()));
        this.sale.links = [{ type: 'Conversation', ref: conversation.id.toString() }].concat(linksToAddToSale);

        this.state.setSale(this.sale).subscribe(() => { });
      }
      if (this.formWorkflow.controls.workflowTemplateId.value) {
        this.state.createConversationWorkflow(this.formWorkflow.value, conversation.id).subscribe(() => { });
      }
      this.taskService.notifyConversationUpdate();
      this.formValueChanged.emit(false);
      this.toast.success(conversation.message);
      this.closeDialog.emit();
    });
  }

  addLinks(links: SearchMemberProspectOptionsModel[]): void {
    const acc = [];
    links.forEach(item => {
      acc.push(new MemberProspectInstancesModel(item));
    });
    this.formLinks = acc;
    this.formValueChanged.emit(true);
  }


  updateInteraction(interaction: InteractionCreateInterface): void {
    this.interaction = interaction;
  }

  updateTask(task: TaskCreateInterface): void {
    this.task = task;
  }

  updateSale(sale: SaleCreateInterface): void {
    this.sale = sale;
  }

  get interactionValid(): boolean {
    return !this.addingInteraction || !!this.interaction;
  }

  get taskValid(): boolean {
    return !this.addingTask || !!this.task;
  }

  get saleValid(): boolean {
    return !this.addingSale || !!this.sale;
  }

  private initializeWorkflow(): void {
    this.workflowOptions = [];
    this.state.getWorkflowTemplates({
      sortBy: 'name',
      pageNumber: 0,
      sort: 'asc',
      status: 'Active',
      type: getWorkflowType(this.form.value.conversationType)
    }).subscribe(data => {
      data.data.forEach(workflow => {
        this.workflowOptions.push(
          {
            value: workflow.id,
            label: workflow.name
          });
      });
    });

    this.formWorkflow.enable();
  }

  private scrollEvent = (event: any): void => {
    if (this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.updatePosition();
    }
    const selectedElement = this.htmlElementRef?.nativeElement.querySelector('.assignedTo-container');

    if (selectedElement && (this.isPanelClosed || this.matAutocompleteTrigger.panelOpen)) {
      const dialogContainer = document.querySelector('.mat-dialog-container');
      if (dialogContainer) {
        const dialogContainerRect = dialogContainer.getBoundingClientRect();
        const dialogContainerTop = dialogContainerRect.top;

        const panelElement = document.querySelector('.mat-autocomplete-panel');
        let panelHeight = panelElement ? panelElement.getBoundingClientRect().height : 96;
        panelHeight = panelHeight > 180 ? panelHeight - 40 : panelHeight;
        const dialogContainerBottom = dialogContainerRect.bottom - (panelHeight);

        const selectedElementRect = selectedElement.getBoundingClientRect();
        const selectedElementTop = selectedElementRect.top;
        const selectedElementBottom = selectedElementRect.bottom;

        const isOutsideDialogBounds =
          selectedElementTop < dialogContainerTop ||
          selectedElementBottom > dialogContainerBottom;

        if (this.matAutocompleteTrigger.panelOpen && isOutsideDialogBounds) {
          this.isPanelClosed = true;
          this.matAutocompleteTrigger.closePanel();
        } else if (!this.matAutocompleteTrigger.panelOpen && !isOutsideDialogBounds && this.isPanelClosed) {
          this.matAutocompleteTrigger.openPanel();
          this.isPanelClosed = false;
        }
      }
    }
  };

  checkFormChanges(): void {
    setTimeout(() => {
      this.initialState = JSON.stringify(this.form.value);
      this.form.valueChanges.subscribe((value) => {
        if (JSON.stringify(value) !== this.initialState) {
          this.formValueChanged.emit(true);
        }
      });
    }, 2000);
  }
}
