import { SelectOption } from '@shared/interfaces';
import {
  ActiveInactiveStatusEnum,
  AnnouncementTypeStatusEnum,
  ApplicationsStatusEnum,
  ApplicationsStatusStringEnum,
  ApplicationsTypeEnum,
  ApprovalModelStatusEnum,
  BannerPositionEnum,
  CampaignTypeStatusEnum,
  ModelTypeApprovalModelStatusEnum,
  ModelTypeRateModelStatusEnum,
  OfferStatusEnum,
  ProductTypeApprovalModelStatusEnum,
  ProductTypeRateModelStatusEnum,
  RateModelStatusEnum,
  RateTypeRateModelStatusEnum,
  UserReportingStatusEnum,
  UserTypeEnum,
} from '@shared/constants/select-options.enums';
import {
  CommunicationTypeEnum,
  CommunicationTypeStringEnum,
  ConversationTypeEnum,
  TaskPriorityEnum,
  TaskPriorityStringEnum,
} from '@shared/enums';
import { ConversationStatusStringEnum } from 'app/modules/conversations/models/conversation-status.enum';
import { OfferTypeEnum } from '@shared/enums/offer-type.enum';
import { GroupTypeEnum } from '@shared/enums/group-type.enum';

export const STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: ConversationStatusStringEnum.Opened,
    label: ConversationStatusStringEnum.Opened,
  },
  {
    value: ConversationStatusStringEnum.Pending,
    label: ConversationStatusStringEnum.Pending,
  },
  {
    value: ConversationStatusStringEnum.Closed,
    label: ConversationStatusStringEnum.Closed,
  },
];

export const OPPORTUNITY_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: ConversationStatusStringEnum.Opened,
    label: ConversationStatusStringEnum.Opened,
  },
  {
    value: ConversationStatusStringEnum.Pending,
    label: ConversationStatusStringEnum.Pending,
  },
  {
    value: ConversationStatusStringEnum.Closed,
    label: ConversationStatusStringEnum.Closed,
  },
  {
    value: ConversationStatusStringEnum.Expired,
    label: ConversationStatusStringEnum.Expired,
  },
  {
    value: ConversationStatusStringEnum.Declined,
    label: ConversationStatusStringEnum.Declined,
  },
];

export const REASON_OPTIONS: SelectOption<string>[] = [
  {
    value: 'PriceRate',
    label: 'Price/Rate',
  },
  {
    value: 'NoLongerInterested',
    label: 'No Longer Interested',
  },
  {
    value: 'DoesNotQualify',
    label: 'Does not qualify',
  },
  {
    value: 'NotRelevant',
    label: 'Not relevant',
  },
  {
    value: 'NotReadyToProceed',
    label: 'Not ready to proceed',
  },
  {
    value: 'BetterProductAtOFI',
    label: 'Better product at OFI',
  },
  {
    value: 'UnableToReach',
    label: 'Unable to Reach',
  },
];

export const TYPE_OPTIONS: SelectOption<string>[] = [
  {
    value: 'Conversation',
    label: 'Conversation',
  },
  {
    value: 'Opportunity',
    label: 'Opportunity',
  },
  {
    value: 'Concern',
    label: 'Concern',
  },
];

export const RESOLUTION_OPTIONS: SelectOption<string>[] = [
  {
    value: 'Unresolved',
    label: 'Unresolved',
  },
  {
    value: 'Resolved',
    label: 'Resolved',
  },
];

export const COMMUNICATION_TYPE_OPTIONS: SelectOption<string>[] = [
  {
    value: CommunicationTypeEnum[CommunicationTypeEnum.Phone],
    label: CommunicationTypeStringEnum.Phone,
  },
  {
    value: CommunicationTypeEnum[CommunicationTypeEnum.Mail],
    label: CommunicationTypeStringEnum.Mail,
  },
  {
    value: CommunicationTypeEnum[CommunicationTypeEnum.InPerson],
    label: CommunicationTypeStringEnum.InPerson,
  },
  {
    value: CommunicationTypeEnum[CommunicationTypeEnum.SMS],
    label: CommunicationTypeStringEnum.SMS,
  },
  {
    value: CommunicationTypeEnum[CommunicationTypeEnum.None],
    label: CommunicationTypeStringEnum.None,
  },
];

export const TASK_TYPE_OPTIONS: SelectOption<string>[] = [
  {
    value: 'FollowUp',
    label: 'Follow-up',
  },
  {
    value: 'Phone',
    label: 'Phone call',
  },
  {
    value: 'Email',
    label: 'E-Mail',
  },
  {
    value: 'General',
    label: 'General',
  },
  {
    value: 'SMS',
    label: 'SMS',
  },
];

export const TASK_PRIORITY_OPTIONS: SelectOption<string>[] = [
  {
    value: TaskPriorityEnum[TaskPriorityEnum.Low],
    label: TaskPriorityStringEnum.Low,
  },
  {
    value: TaskPriorityEnum[TaskPriorityEnum.Medium],
    label: TaskPriorityStringEnum.Medium,
  },
  {
    value: TaskPriorityEnum[TaskPriorityEnum.High],
    label: TaskPriorityStringEnum.High,
  },
];

export const CONVERSATION_TABLE_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: ConversationStatusStringEnum.Opened,
    label: ConversationStatusStringEnum.Opened,
  },
  {
    value: ConversationStatusStringEnum.Pending,
    label: ConversationStatusStringEnum.Pending,
  },
  {
    value: ConversationStatusStringEnum.Closed,
    label: ConversationStatusStringEnum.Closed,
  },
  {
    value: '',
    label: 'All',
  },
];

export const OPPORTUNITIES_TABLE_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: ConversationStatusStringEnum.Opened,
    label: ConversationStatusStringEnum.Opened,
  },
  {
    value: ConversationStatusStringEnum.Pending,
    label: ConversationStatusStringEnum.Pending,
  },
  {
    value: ConversationStatusStringEnum.Closed,
    label: ConversationStatusStringEnum.Closed,
  },
  {
    value: ConversationStatusStringEnum.Expired,
    label: ConversationStatusStringEnum.Expired,
  },
  {
    value: ConversationStatusStringEnum.Declined,
    label: ConversationStatusStringEnum.Declined,
  },
  {
    value: '',
    label: 'All',
  },
];

export const PROBABILITY_OPTIONS: SelectOption<string>[] = [
  {
    value: 'Unknown',
    label: 'Unknown',
  },
  {
    value: 'Low',
    label: 'Low',
  },
  {
    value: 'Medium',
    label: 'Medium',
  },
  {
    value: 'High',
    label: 'High',
  },
];

export const CONVERSATION_TYPE_FILTER_OPTIONS: SelectOption<number>[] = [
  {
    value: ConversationTypeEnum.Conversation,
    label: 'Conversation',
  },
  {
    value: ConversationTypeEnum.Opportunity,
    label: 'Opportunity',
  },
  {
    value: ConversationTypeEnum.Concern,
    label: 'Concern',
  },
  {
    value: ConversationTypeEnum.All,
    label: 'All',
  },
];

export const COMMUNICATION_TYPE_FILTER_OPTIONS: SelectOption<string>[] = [
  {
    value: CommunicationTypeEnum[CommunicationTypeEnum.Phone],
    label: CommunicationTypeStringEnum.Phone,
  },
  {
    value: CommunicationTypeEnum[CommunicationTypeEnum.Mail],
    label: CommunicationTypeStringEnum.Mail,
  },
  {
    value: CommunicationTypeEnum[CommunicationTypeEnum.InPerson],
    label: CommunicationTypeStringEnum.InPerson,
  },
  {
    value: CommunicationTypeEnum[CommunicationTypeEnum.SMS],
    label: CommunicationTypeStringEnum.SMS,
  },
  {
    value: CommunicationTypeEnum[CommunicationTypeEnum.All],
    label: CommunicationTypeStringEnum.All,
  },
  {
    value: CommunicationTypeEnum[CommunicationTypeEnum.None],
    label: CommunicationTypeStringEnum.None,
  },
];

export const PROBABILITY_FILTER_OPTIONS: SelectOption<string>[] = [
  {
    value: 'Unknown',
    label: 'Unknown',
  },
  {
    value: 'Low',
    label: 'Low',
  },
  {
    value: 'Medium',
    label: 'Medium',
  },
  {
    value: 'High',
    label: 'High',
  },
  {
    value: 'All',
    label: 'All',
  },
];

export const STATUS_ACTIVE_INACTIVE_OPTIONS: SelectOption<string>[] = [
  {
    value: ActiveInactiveStatusEnum[ActiveInactiveStatusEnum.Active],
    label: 'Active',
  },
  {
    value: ActiveInactiveStatusEnum[ActiveInactiveStatusEnum.Inactive],
    label: 'Inactive',
  },
];

export const STATUS_ACTIVE_INACTIVE_ALL_OPTIONS: SelectOption<string>[] = [
  {
    value: ActiveInactiveStatusEnum[ActiveInactiveStatusEnum.Active],
    label: 'Active',
  },
  {
    value: ActiveInactiveStatusEnum[ActiveInactiveStatusEnum.Inactive],
    label: 'Inactive',
  },
  {
    value: '',
    label: 'All',
  },
];

export const CAMPAIGN_TYPE_OPTIONS: SelectOption<string>[] = [
  {
    value: CampaignTypeStatusEnum[CampaignTypeStatusEnum.Retail],
    label: 'Retail',
  },
  {
    value: CampaignTypeStatusEnum[CampaignTypeStatusEnum.Business],
    label: 'Business',
  },
];

export const ANNOUNCEMENT_TYPE_OPTIONS: SelectOption<string>[] = [
  {
    value: AnnouncementTypeStatusEnum[AnnouncementTypeStatusEnum.Global],
    label: 'Global',
  },
  {
    value: AnnouncementTypeStatusEnum[AnnouncementTypeStatusEnum.Branch],
    label: 'Branch',
  },
];

export const BANNER_POSITION_OPTIONS: SelectOption<string>[] = [
  {
    value: BannerPositionEnum[BannerPositionEnum.header],
    label: 'Header',
  },
  {
    value: BannerPositionEnum[BannerPositionEnum.footer],
    label: 'Footer',
  },
];

export const APPLICATIONS_TYPE_OPTIONS: SelectOption<string>[] = [
  {
    value: ApplicationsTypeEnum[ApplicationsTypeEnum.Retail],
    label: 'Retail',
  },
  {
    value: ApplicationsTypeEnum[ApplicationsTypeEnum.Business],
    label: 'Business',
  },
  {
    value: ApplicationsTypeEnum[ApplicationsTypeEnum.All],
    label: 'All',
  },
];

export const APPLICATIONS_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.InProgress],
    label: ApplicationsStatusStringEnum.InProgress,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.Pending],
    label: ApplicationsStatusStringEnum.Pending,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.Cheque_Clearing],
    label: ApplicationsStatusStringEnum.Cheque_Clearing,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.Completed],
    label: ApplicationsStatusStringEnum.Completed,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.Rejected],
    label: ApplicationsStatusStringEnum.Rejected,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.Declined],
    label: ApplicationsStatusStringEnum.Declined,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.Returned],
    label: ApplicationsStatusStringEnum.Returned,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.ReferredToBranch],
    label: ApplicationsStatusStringEnum.ReferredToBranch,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.Verified],
    label: ApplicationsStatusStringEnum.Verified,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.Abandoned],
    label: ApplicationsStatusStringEnum.Abandoned,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.ReferredToBranch],
    label: ApplicationsStatusStringEnum.ReferredToBranch,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.AwaitingFunding],
    label: ApplicationsStatusStringEnum.AwaitingFunding,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.LendingHalted],
    label: ApplicationsStatusStringEnum.LendingHalted,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.MortgageLead],
    label: ApplicationsStatusStringEnum.MortgageLead,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.ManagerReviewRequired],
    label: ApplicationsStatusStringEnum.ManagerReviewRequired,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.InsuranceFailed],
    label: ApplicationsStatusStringEnum.InsuranceFailed,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.FundingReceived],
    label: ApplicationsStatusStringEnum.FundingReceived,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.ClosedTransferred],
    label: ApplicationsStatusStringEnum.ClosedTransferred,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.Expired],
    label: ApplicationsStatusStringEnum.Expired,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.RejectedFraudulent],
    label: ApplicationsStatusStringEnum.RejectedFraudulent,
  },
  {
    value: ApplicationsStatusEnum[ApplicationsStatusEnum.All],
    label: ApplicationsStatusStringEnum.All,
  },
];

export const USER_TYPE_OPTIONS: SelectOption<string | number>[] = [
  {
    value: UserTypeEnum[UserTypeEnum.Full],
    label: 'Full User',
  },
  {
    value: UserTypeEnum[UserTypeEnum.Team],
    label: 'Team User',
  },
  {
    value: UserTypeEnum.All,
    label: 'All',
  },
];

export const USER_REPORTING_STATUS_OPTIONS: SelectOption<string | number>[] = [
  {
    value: UserReportingStatusEnum[UserReportingStatusEnum.Deleted],
    label: 'Deleted',
  },
  {
    value: UserReportingStatusEnum[UserReportingStatusEnum.New],
    label: 'New',
  },
  {
    value: UserReportingStatusEnum[UserReportingStatusEnum.Pending],
    label: 'Pending',
  },
  {
    value: UserReportingStatusEnum[UserReportingStatusEnum.Disabled],
    label: 'Disabled',
  },
  {
    value: UserReportingStatusEnum[UserReportingStatusEnum.Active],
    label: 'Active',
  },
  {
    value: UserReportingStatusEnum.All,
    label: 'All',
  },
];

export const STATUS_OFFER_OPTIONS: SelectOption<string>[] = [
  {
    value: OfferStatusEnum[OfferStatusEnum.All],
    label: 'All',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Active],
    label: 'Active',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Inactive],
    label: 'Inactive',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Expired],
    label: 'Expired',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Closed],
    label: 'Closed',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Fulfilled],
    label: 'Fulfilled',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Declined],
    label: 'Declined',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Deleted],
    label: 'Deleted',
  },
];

export const TYPE_OFFER_OPTIONS: SelectOption<string>[] = [
  {
    value: OfferTypeEnum[OfferTypeEnum.Offer],
    label: 'Offer',
  },
  {
    value: OfferTypeEnum[OfferTypeEnum.Notice],
    label: 'Notice',
  },
];

export const STATUS_EDIT_OFFER_OPTIONS: SelectOption<string>[] = [
  {
    value: OfferStatusEnum[OfferStatusEnum.Active],
    label: 'Active',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Inactive],
    label: 'Inactive',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Expired],
    label: 'Expired',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Closed],
    label: 'Closed',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Fulfilled],
    label: 'Fulfilled',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Declined],
    label: 'Declined',
  },
  {
    value: OfferStatusEnum[OfferStatusEnum.Deleted],
    label: 'Deleted',
  },
];

export const GROUP_TYPE: SelectOption<string>[] =  [
  {
    value: GroupTypeEnum[GroupTypeEnum.CRMAssignment],
    label: 'CRM Assignment',
  },
  {
    value: GroupTypeEnum[GroupTypeEnum.ECMFolderPermission],
    label: 'ECM Folder Permission',
  },
  {
    value: GroupTypeEnum[GroupTypeEnum.RepresentativeRole],
    label: 'Representative Role',
  },
  {
    value: GroupTypeEnum[GroupTypeEnum.All],
    label: 'All',
  },

];

export const GROUP_TYPE_OPTIONS: SelectOption<string>[] =  [
  {
    value: GroupTypeEnum[GroupTypeEnum.CRMAssignment],
    label: 'CRM Assignment',
  },
  {
    value: GroupTypeEnum[GroupTypeEnum.ECMFolderPermission],
    label: 'ECM Folder Permission',
  },
  {
    value: GroupTypeEnum[GroupTypeEnum.RepresentativeRole],
    label: 'Representative Role',
  },

];

export const STATUS_APPROVAL_MODELS_OPTIONS: SelectOption<string>[] = [
  {
    value: ApprovalModelStatusEnum[ApprovalModelStatusEnum.All],
    label: 'All',
  },
  {
    value: ApprovalModelStatusEnum[ApprovalModelStatusEnum.Active],
    label: 'Active',
  },
  {
    value: ApprovalModelStatusEnum[ApprovalModelStatusEnum.Inactive],
    label: 'Inactive',
  },
];

export const MODEL_TYPE_APPROVAL_MODELS_OPTIONS: SelectOption<string>[] = [
  {
    value:
      ModelTypeApprovalModelStatusEnum[ModelTypeApprovalModelStatusEnum.All],
    label: 'All',
  },
  {
    value:
      ModelTypeApprovalModelStatusEnum[
        ModelTypeApprovalModelStatusEnum.Business
      ],
    label: 'Business',
  },
  {
    value:
      ModelTypeApprovalModelStatusEnum[ModelTypeApprovalModelStatusEnum.Retail],
    label: 'Retail',
  },
];

export const PRODUCT_TYPE_APPROVAL_MODELS_OPTIONS: SelectOption<string>[] = [
  {
    value:
      ProductTypeApprovalModelStatusEnum[
        ProductTypeApprovalModelStatusEnum.All
      ],
    label: 'All',
  },
  {
    value:
      ProductTypeApprovalModelStatusEnum[
        ProductTypeApprovalModelStatusEnum.LOC
      ],
    label: 'LOC',
  },
  {
    value:
      ProductTypeApprovalModelStatusEnum[
        ProductTypeApprovalModelStatusEnum.Loan
      ],
    label: 'Loan',
  },
  {
    value:
      ProductTypeApprovalModelStatusEnum[
        ProductTypeApprovalModelStatusEnum.Mortgage
      ],
    label: 'Mortgage',
  },
];

export const STATUS_RATE_MODELS_OPTIONS: SelectOption<string>[] = [
  {
    value: RateModelStatusEnum[RateModelStatusEnum.All],
    label: 'All',
  },
  {
    value: RateModelStatusEnum[RateModelStatusEnum.Active],
    label: 'Active',
  },
  {
    value: RateModelStatusEnum[RateModelStatusEnum.Inactive],
    label: 'Inactive',
  },
];

export const MODEL_TYPE_RATE_MODELS_OPTIONS: SelectOption<string>[] = [
  {
    value:
      ModelTypeRateModelStatusEnum[ModelTypeRateModelStatusEnum.All],
    label: 'All',
  },
  {
    value:
      ModelTypeRateModelStatusEnum[
        ModelTypeRateModelStatusEnum.Business
      ],
    label: 'Business',
  },
  {
    value:
      ModelTypeRateModelStatusEnum[ModelTypeRateModelStatusEnum.Retail],
    label: 'Retail',
  },
];

export const PRODUCT_TYPE_RATE_MODELS_OPTIONS: SelectOption<string>[] = [
  {
    value:
      ProductTypeRateModelStatusEnum[
        ProductTypeRateModelStatusEnum.All
      ],
    label: 'All',
  },
  {
    value:
      ProductTypeRateModelStatusEnum[
        ProductTypeRateModelStatusEnum.LOC
      ],
    label: 'LOC',
  },
  {
    value:
      ProductTypeRateModelStatusEnum[
        ProductTypeRateModelStatusEnum.Loan
      ],
    label: 'Loan',
  },
  {
    value:
      ProductTypeRateModelStatusEnum[
        ProductTypeRateModelStatusEnum.Mortgage
      ],
    label: 'Mortgage',
  },
];

export const RATE_TYPE_RATE_MODELS_OPTIONS: SelectOption<string>[] = [
  {
    value:
      RateTypeRateModelStatusEnum[RateTypeRateModelStatusEnum.All],
    label: 'All',
  },
  {
    value:
      RateTypeRateModelStatusEnum[
        RateTypeRateModelStatusEnum.Fixed
      ],
    label: 'Fixed',
  },
  {
    value:
      RateTypeRateModelStatusEnum[RateTypeRateModelStatusEnum.Variable],
    label: 'Variable',
  },
];

export const EXPECTED_VALUE_COMPUTATION_OPTIONS: SelectOption<number>[] = [
  {
    value: 1,
    label: 'Unique-Account Balance',
  },
  {
    value: 2,
    label: 'Unique-Available Balance',
  },
  {
    value: 3,
    label: 'Unique-Outstanding Balance',
  },
  {
    value: 4,
    label: 'Shared Value',
  },
];

export const PERIOD_OPTIONS: string[] = ['Days', 'Weeks', 'Months'];

export const PROBABILITY_SELECT_OPTIONS: SelectOption<number>[] = [
  {
    value: 1,
    label: 'Unknown',
  },
  {
    value: 2,
    label: 'Low',
  },
  {
    value: 3,
    label: 'Medium',
  },
  {
    value: 4,
    label: 'High',
  },
];


export const RESOLUTION_SELECT_OPTIONS: SelectOption<number>[] = [
  {
    value: 1,
    label: 'Unresolved',
  },
  {
    value: 2,
    label: 'Resolved',
  },
];

export const IMPORT_TYPE_SELECT_OPTIONS: SelectOption<number>[] = [
  {
    value:1,
    label:'Manual'
  },
  {
    value:2,
    label:'Opportunity Engine'
  }
];


export const CONVERSATION_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: ConversationStatusStringEnum.Opened,
    label: ConversationStatusStringEnum.Opened,
  },
  {
    value: ConversationStatusStringEnum.Pending,
    label: ConversationStatusStringEnum.Pending,
  },
  {
    value: ConversationStatusStringEnum.Processed,
    label: ConversationStatusStringEnum.Processed,
  },
  {
    value: ConversationStatusStringEnum.Closed,
    label: ConversationStatusStringEnum.Closed,
  },
  {
    value: '',
    label: 'All',
  },
];

export const OPPORTUNITIES_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: ConversationStatusStringEnum.Opened,
    label: ConversationStatusStringEnum.Opened,
  },
  {
    value: ConversationStatusStringEnum.Pending,
    label: ConversationStatusStringEnum.Pending,
  },
  {
    value: ConversationStatusStringEnum.Processed,
    label: ConversationStatusStringEnum.Processed,
  },
  {
    value: ConversationStatusStringEnum.Closed,
    label: ConversationStatusStringEnum.Closed,
  },
  {
    value: ConversationStatusStringEnum.Expired,
    label: ConversationStatusStringEnum.Expired,
  },
  {
    value: ConversationStatusStringEnum.Declined,
    label: ConversationStatusStringEnum.Declined,
  },
  {
    value: '',
    label: 'All',
  },
];
